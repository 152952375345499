<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="业务类型">
              <a-select
                placeholder="请选择业务类型"
                style="width: 100%"
                v-model="businessType"
                @change="handleBizTypeChange"
              >
                <a-select-option :key="1">查验业务</a-select-option>
                <a-select-option :key="2">暂落箱业务</a-select-option>
                <a-select-option :key="5">客服业务</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" class="range-date">
            <a-form-item label="入库时间">
              <a-range-picker @change="onDateChange" allowClear/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="委托客户">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="businessType === 1">
            <a-form-item label="计划号">
              <a-input v-model="queryParam.plan_id" allowClear/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-else>
            <a-form-item label="业务编号">
              <a-input v-model="queryParam.serial_num" allowClear/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan" v-if="businessType === 5">
            <a-form-item label="品名">
              <a-input v-model="queryParam.good_name" allowClear/>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :xs="6">
            <span class="table-page-search-submitButtons">
              <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button type="primary" @click="handleExport()">导出</a-button>
              <a-popover v-model="dateVisible" title="选择日期" trigger="click" v-if="businessType === 5">
                <a slot="content">
                  <p><a-range-picker @change="onWeekDateChange" style="width: 200px" /></p>
                  <p style="text-align: right"><a-button type="primary" @click="handleDateTimeStaticsExport">确定</a-button></p>
                </a>
                <a-button type="primary">导出周报统计表</a-button>
              </a-popover>
              <a-popover v-model="popVisible" title="选择月份" trigger="click" v-if="businessType === 5">
                <a slot="content">
                  <p><a-month-picker v-model="exportDate" style="width: 200px" format="YYYY/MM" placeholder="请选择月份" /></p>
                  <p style="text-align: right"><a-button type="primary" @click="handleStaticsExport">确定</a-button></p>
                </a>
                <a-button type="primary">导出月度统计表</a-button>
              </a-popover>
              <a-popover v-model="yearVisible" title="选择年份" trigger="click" v-if="businessType === 5">
                <a slot="content">
                  <p>
                    <a-date-picker
                      :value="exportYear"
                      mode="year"
                      style="width: 200px"
                      format="YYYY"
                      :open="yearChoose"
                      placeholder="请选择年份"
                      @panelChange="chooseYear"
                      @openChange="handleYearOpen"
                    />
                  </p>
                  <p style="text-align: right"><a-button type="primary" @click="handleYearStaticsExport">确定</a-button></p>
                </a>
                <a-button type="primary">导出年度统计表</a-button>
              </a-popover>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <s-table
      ref="table"
      size="middle"
      :dynamicColumns="true"
      :scroll="{ x: 'max-content' }"
      :rowKey="function(record) {return getRandomCode(8)}"
      :columns="columns"
      :alert="{
        show: true,
        msg: showTotal
      }"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="boxInfoList" slot-scope="text" v-if="businessType === 2">
        <a-popover placement="bottom">
          <template v-if="text && text.length > 0">
            <span>
              {{ text[0].box_name ? `类型：${text[0].box_name}` : '' }}
              {{ text[0].box_num ? ` 箱号：${text[0].box_num}` : '' }}
              {{ text[0].enter_time ? ` 进场时间：${moment(text[0].enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ text[0].exit_time ? ` 出场时间：${moment(text[0].exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
            <span style="color: #FAAD14;">{{ text.length !== 0 ? `(${text.length})单` : null }}</span>
          </template>
          <template slot="content">
            <span v-for="item in text" :key="item.id" style="display:block">
              {{ item.box_name ? `类型：${item.box_name}` : '' }}
              {{ item.box_num ? ` 箱号：${item.box_num}` : '' }}
              {{ item.enter_time ? ` 进场时间：${moment(item.enter_time).format('YYYY/MM/DD')}` : '' }}
              {{ item.exit_time ? ` 出场时间：${moment(item.exit_time).format('YYYY/MM/DD')}` : '' }}
            </span>
          </template>
        </a-popover>
      </div>
    </s-table>
  </a-card>
</template>
<script>
import { STable } from '@/components'
import { getCustomer, downloadExcel } from '@/api/common'
import { exportWmsStat, getWmsDataOption, wmsInStat, wmsOutStat } from '@/api/wms'
import { getRandomCode, filterOption } from '@/utils/util'
import { exportCumsReport, postCumsStaticsPage, exportCumsFinance } from '@/api/cums'
import moment from 'moment'
import debounce from 'lodash/debounce';
export default {
  components: {
    STable
  },
  data() {
    this.lastFetchId = 0;
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    return {
      queryParam: {},
      // 客服部搜索条件
      cumsQueryParam: {},
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      showTotal: '',
      columns: [],
      cmsColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 80
        },
        {
          title: '报关单号',
          dataIndex: 'report_num',
          width: 120
        },
        {
          title: '计划号',
          dataIndex: 'plan_id',
          width: 120
        },
        {
          title: '报关单位',
          dataIndex: 'customs_name',
          width: 120
        },
        {
          title: '品名',
          dataIndex: 'total_name',
          width: 100
        },
        {
          title: '件数',
          dataIndex: 'num',
          width: 80
        },
        {
          title: '毛重',
          dataIndex: 'total_gross_weight',
          width: 80
        },
        {
          title: '净重',
          dataIndex: 'total_net_weight',
          width: 80
        },
        {
          title: '总天数',
          dataIndex: 'wms_days',
          width: 80
        },
        {
          title: '总体积',
          dataIndex: 'total_volume',
          width: 80
        },
        {
          title: '监管方式',
          dataIndex: 'supervise_name',
          width: 100
        },
        {
          title: '布控指令',
          dataIndex: 'control_command',
          width: 100
        },
        {
          title: '箱型',
          dataIndex: 'box_type_name',
          width: 80
        },
        {
          title: '箱量',
          dataIndex: 'box_num',
          width: 80
        },
        {
          title: '是否押运',
          dataIndex: 'escort',
          width: 100,
          customRender: text => {
            return text ? '是' : '否'
          }
        },
        {
          title: '工号',
          dataIndex: 'worker_num',
          width: 80
        },
        {
          title: '金额',
          dataIndex: 'charge',
          width: 80
        },
        {
          title: '查验情况简述',
          dataIndex: 'sketch',
          width: 150
        },
        {
          title: '货物处置',
          dataIndex: 'commodity_deal',
          width: 100
        },
        {
          title: '查验方式',
          dataIndex: 'inspect',
          width: 100
        },
        {
          title: '货物状态',
          dataIndex: 'commodity_type_name',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100
        },
        {
          title: '仓储进库日期',
          dataIndex: 'in_date',
          width: 130,
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '仓储出库日期',
          dataIndex: 'out_date',
          width: 130,
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '入库总立方',
          dataIndex: 'in_cube',
          width: 130
        },
        {
          title: '入库操作员',
          dataIndex: 'in_operator',
          customRender: text => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return text
          },
          width: 130
        },
        {
          title: '出库总立方',
          dataIndex: 'out_cube',
          width: 130
        },
        {
          title: '出库操作员',
          dataIndex: 'out_operator',
          customRender: text => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return text
          },
          width: 130
        },
        {
          title: '数量',
          dataIndex: 'total_num',
          width: 130
        },
        {
          title: '库存立方',
          dataIndex: 'kclf',
          width: 130
        }
      ],
      tmpColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center'
        },
        {
          title: '委托客户',
          dataIndex: 'customer'
        },
        {
          title: '操作员',
          dataIndex: 'operator_id',
          customRender: (text) => {
            for (const item of this.operatorOps) {
              if (text === item.value) {
                return item.name
              }
            }
            return ''
          }
        },
        {
          title: '箱信息',
          dataIndex: 'box_info_list',
          scopedSlots: { customRender: 'boxInfoList' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 200
        }
      ],
      cumsColumns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 50
        },
        {
          title: '委托客户',
          dataIndex: 'agency_name',
          width: 150
        },
        {
          title: '进库核注清单号',
          dataIndex: 'check_num',
          width: 130
        },
        {
          title: '商品名称',
          dataIndex: 'good_name',
          width: 150
        },
        {
          title: '规格型号',
          dataIndex: 'good_spec',
          width: 100
        },
        {
          title: '商品料号',
          dataIndex: 'good_material',
          width: 100
        },
        {
          title: '货物数量',
          dataIndex: 'num',
          width: 100
        },
        {
          title: '货物单位',
          dataIndex: 'unit',
          width: 80
        },
        {
          title: '申报数量',
          dataIndex: 'upload_num',
          width: 100
        },
        {
          title: '申报计量单位',
          dataIndex: 'upload_unit',
          width: 110
        },
        {
          title: '法定数量',
          dataIndex: 'legal_num',
          width: 100
        },
        {
          title: '法定计量单位',
          dataIndex: 'legal_unit',
          width: 110
        },
        {
          title: '已清关货物单位数量',
          dataIndex: 'total_clear',
          width: 100
        },
        {
          title: '未清关货物单位数量',
          dataIndex: 'un_clear',
          width: 100
        },
        {
          title: '已清关申报计量单位数量',
          dataIndex: 'total_clear_upload',
          width: 120
        },
        {
          title: '出库核注清单号',
          dataIndex: 'ex_check_num',
          width: 120
        },
        {
          title: '出库日期',
          dataIndex: 'export_date',
          width: 100,
          customRender: text => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '客户出库单号',
          dataIndex: 'export_num',
          width: 120
        },
        {
          title: '出库货物单位数量',
          dataIndex: 'total_export',
          width: 100
        },
        {
          title: '出库申报计量单位数量',
          dataIndex: 'total_export_legal',
          width: 100
        },
        {
          title: '库存货物单位数量',
          dataIndex: 'total_storage',
          width: 100
        },
        {
          title: '库存申报计量单位数量',
          dataIndex: 'total_storage_legal',
          width: 100
        }
      ],
      operatorOps: [],
      loadData: parameter => {
        if (this.businessType === 5) {
          this.cumsQueryParam['check_num'] = this.queryParam.serial_num
          this.cumsQueryParam['good_name'] = this.queryParam.good_name
          return postCumsStaticsPage('list_storage', Object.assign(parameter, this.cumsQueryParam)).then(res => {
            if (res.total_row) {
              this.showTotal = '货物数量:' + res.total_row.total_num + '; 出库货物单位数量:' + res.total_row.total_export
            } else {
              this.showTotal = '货物数量:0 ; 出库货物单位数量:0'
            }
            return res
          })
        } else if (this.businessType === 1) {
          return wmsInStat(this.getBusinessType(this.businessType), Object.assign(parameter, this.queryParam)).then(res => {
            res.list.forEach(data => {
              if (typeof data.in_cube === 'undefined' && typeof data.out_cube === 'undefined') {
                data.kclf = 0
              } else if (typeof data.in_cube === 'undefined' && typeof data.out_cube !== 'undefined') {
                data.kclf = 0 - data.out_cube
              } else if (typeof data.in_cube !== 'undefined' && typeof data.out_cube === 'undefined') {
                data.kclf = data.in_cube
              } else if (typeof data.in_cube !== 'undefined' && typeof data.out_cube !== 'undefined') {
                data.kclf = data.in_cube - data.out_cube
              }
              this.showTotal =
                  '箱型合计：' +
                  res.total_row.box +
                  ' 入库总立方：' +
                  res.total_row.in +
                  ' 出库总立方：' +
                  res.total_row.out
              return res
            })
            return res
          })
        } else if (this.businessType === 2) {
          return wmsOutStat(this.getBusinessType(this.businessType), Object.assign(parameter, this.queryParam))
            .then(res => {
              this.showTotal = '箱型合计：' + res.total_row.box
              return res
            })
        }
      },
      businessType: 2,
      dateVisible: false,
      popVisible: false,
      yearVisible: false,
      exportDate: null,
      yearChoose: false,
      exportYear: null,
      exportDateTime: null,
      export_start_date: null,
      export_end_date: null
    }
  },
  created() {
    getWmsDataOption('operator').then(v => {
      this.operatorOps = v
    })
    this.columns = this.tmpColumns
  },
  methods: {
    getRandomCode,
    filterOption,
    moment,
    handleExport() {
      this.queryParam.business_type = this.businessType
      if (this.businessType === 5) {
        this.cumsQueryParam['auth_agency'] = this.queryParam['customer_id']
        this.cumsQueryParam['check_num'] = this.queryParam['serial_num']
        exportCumsReport('export_storage_list', this.cumsQueryParam).then(res => {
          if (res !== null) {
            const fileInfo = {
              fileName: res
            }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
      } else {
        exportWmsStat('in', {
          ...this.queryParam
        }).then(res => {
          if (res !== null) {
            const fileInfo = {
              fileName: res
            }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        })
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: 1,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (value) {
        this.customerInfo = value
        this.queryParam['customer_id'] = value.key
        this.cumsQueryParam['auth_agency'] = value.key
      } else {
        this.customerInfo = undefined
        this.queryParam['customer_id'] = null
        this.cumsQueryParam['auth_agency'] = null
      }
      this.customerOps = []
      this.fetching = false
    },
    handleBizTypeChange(a) {
      switch (a) {
        case 1:
          this.columns = this.cmsColumns
          this.queryParam['business_type'] = 1
          break;
        case 2:
          this.columns = this.tmpColumns
          this.queryParam['business_type'] = 2
          break;
        case 5:
          this.columns = this.cumsColumns
          this.queryParam['business_type'] = 5
          break;
        default:
          this.businessType = 2
          this.queryParam['business_type'] = 2
          this.columns = this.tmpColumns
          break;
      }
      this.$refs.table.refresh(true)
    },
    onDateChange(date, dateString) {
      if (date[0]) {
        this.queryParam['enter_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enter_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enterStartTime'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['enterEndTime'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        // this.cumsQueryParam['enter_start_time'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        // this.cumsQueryParam['enter_end_time'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        // this.cumsQueryParam['enterStartTime'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        // this.cumsQueryParam['enterEndTime'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.cumsQueryParam['start_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.cumsQueryParam['end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['enter_start_time'] = null
        this.queryParam['enter_end_time'] = null
        this.queryParam['enterStartTime'] = null
        this.queryParam['enterEndTime'] = null
        // this.cumsQueryParam['enter_start_time'] = null
        // this.cumsQueryParam['enter_end_time'] = null
        // this.cumsQueryParam['enterStartTime'] = null
        // this.cumsQueryParam['enterEndTime'] = null
        this.cumsQueryParam['start_date'] = null
        this.cumsQueryParam['end_date'] = null
      }
    },
    getBusinessType(value) {
      switch (value) {
        case 1:
          return 'stat_cms'
        case 2:
          return 'stat_tmp'
        case 5:
          return 'stat_cums'
        default:
          this.businessType = 2
          this.columns = this.tmpColumns
          return 'stat_tmp'
      }
    },
    chooseYear(date) {
      this.exportYear = date
      this.yearChoose = false
    },
    handleYearOpen(status) {
      if (status) {
        this.yearChoose = true
      } else {
        this.yearChoose = false
      }
    },
    handleDateTimeStaticsExport() {
      if (this.export_start_date && this.export_end_date) {
        this.handleDateStaticsExport('export_datetime', {
          firstDateTime: this.export_start_date,
          endDateTime: this.export_end_date
        })
        this.dateVisible = false
      } else {
        this.$message.warning('请选择导出日期')
      }
    },
    handleStaticsExport() {
      if (this.exportDate) {
        const year = this.exportDate.year()
        const month = this.exportDate.month()
        this.handleDateStaticsExport('export_data', {
          year: year,
          month: month + 1
        })
        this.popVisible = false
      } else {
        this.$message.warning('请选择导出月份')
      }
    },
    handleYearStaticsExport() {
      if (this.exportYear) {
        const year = this.exportYear.year()
        this.handleDateStaticsExport('export_year', {
          year: year
        })
        this.yearVisible = false
      } else {
        this.$message.warning('请选择导出年份')
      }
    },
    onWeekDateChange(date, dateString) {
      this.export_start_date = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.export_end_date = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
    },
    handleDateStaticsExport(type, queryParam) {
      exportCumsFinance(type, queryParam || this.queryParam).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
        }
      })
    }
  }
}
</script>
